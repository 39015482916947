.formDiv {
  background-color: var(--lightmedium-gray-price);
  text-align: center;
  // padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  // border: red 10px solid;

  // @media all and (max-width: 480px) {
  // }
  // @media all and (min-width: 481px) {
  //   // padding-top: 1rem;
  //   // padding-bottom: 1rem;
  // }
  // @media all and (min-width: 780px) {
  // }
  // @media all and (min-width: 1020px) {
  // }
}

.form {
  // // border: red 10px solid;
  // width: 100%;
  // @media all and (max-width: 480px) {
  // }
  // @media all and (min-width: 481px) {
  //   // padding-top: 1rem;
  //   // padding-bottom: 1rem;
  //   width: 100%;
  // }
  // @media all and (min-width: 780px) {
  //   width: 75%;
  // }
  // @media all and (min-width: 1020px) {
  //   width: 60%;
  // }
}
