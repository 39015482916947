.errorStyling {
  color: red;
  font-size: 0.88rem;
}

.Button {
  margin-right: 10px;
}

.requiredFields {
  font-size: 0.85rem;
}

.requiredFields span {
  font-size: 0.99rem;
}
