nav.nav-header {
  background-color: #DBDBDB;
}

.logo {
  max-width: 10rem;
}

.registerButton {
  border-radius: 10px;
  background-color: #9966ff;
  padding: 1rem 0.8rem;
  text-decoration: none;
  color: #FFFF;
  margin-right: 20px;
}

.registerButton:hover {
  color: #49169c;
}

.loginButton {
  border-radius: 10px;
  background-color: #3d3d3c;
  padding: 1rem 0.8rem;
  text-decoration: none;
  color: #FFFF;
}

.loginButton:hover {
  color: #a2a1a3;
}

.newContainer {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.header {
  position: sticky;
  top: 0;
}

form input {
  font-family: inherit;
  border-radius: 0.4rem;
  padding: 0.5rem 0.2rem 0.5rem 0.2rem;
}

div form {
  gap: 10px;
}


@media (min-width: 992px) {
  .container-header {
      max-width: 93% !important;
  }
}