.FilterList {
  position: absolute;
  max-height: 250px;
  overflow: auto;
  max-width: 190px;
  min-width: 150px;
  left: 0;
  top: 100%;
  border: 2px solid #8e9193;
}

.FilterListItem {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 5px 0 5px;
  // padding-left: 30px;
  height: 35px;
  // line-height: 35px;
  font-size: 14px;
  // text-align: left;

  &:nth-child(odd) {
    background-color: #e2e2e2;
  }

  &:nth-child(even) {
    background-color: #FFFFFF;
  }

}


.SearchInput {
  // position: relative;
  width: 100%;
  height: 24px;
}

.IconMdClear {
  margin-left: 3px;
  font-size: 24px;
}