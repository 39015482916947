@import '../../styles/mixins';

.root {
    display: flex;
    height: 100%;
}

.page {
    flex: 1;
    flex-basis: 55%;

    & > div {
        margin-bottom: 0;
        
        & > div {
            display: flex;
            height: 100%;
        }
    }
}

.card {
    margin: auto;
    width: 320px;
   

    @keyframes appearFromLeft {
        from {
            opacity: 0;
            transform: translateX(-50px);
          }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    animation: appearFromLeft 1s;    
}

.quote {
    flex: 1;
    flex-basis: 45%;
    height: 100%;
    position: relative;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            display: block;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}


.errorStyling {
    margin-top: 10px;
    color: red;
    font-size: 0.8rem;
  };


.label{
    display: block;
};


