a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

#FootbarDiv {
  background-color: #262626;
  // background-image: linear-gradient(
  //   0deg,
  //   rgb(153, 102, 255) 0%,
  //   rgb(87, 80, 107) 50%,
  //   rgb(48, 48, 48) 100%
  // );
  color: var(--lightmedium-gray-price);
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-wrap: auto;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;

  @media all and (max-width: 767px) {
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
  }
  @media all and (min-width: 768px) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    flex-direction: column;
    flex-direction: row;
  }
  @media all and (min-width: 1020px) {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
  }
}

.footerIcon {
  width: 25%;
  // display: none;
  text-align: center;
  @media all and (max-width: 767px) {
    margin: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}

#logo {
  justify-content: center;
  align-items: center;
}

#socialMedia {
  align-items: center;
  text-align: center;
  justify-content: center;
  @media all and (max-width: 767px) {
    text-align: start;
    justify-content: start;
  }
}

.footerItems a {
  font-size: 0.8rem;
  text-align: center;
  display: flex;
  color: inherit;
  text-decoration: none;
  transition: 0.2s;
}

.footerItems a:hover {
  color: #878787;
  text-decoration: none;
}

.footerSocial {
  display: flex;
  font-display: column;
  color: inherit;
  text-decoration: none;
  justify-content: center;
  @media all and (max-width: 767px) {
    text-align: start;
    justify-content: start;
  }
}

.footerSocial a {
  color: inherit;
  transition: 0.3s;
}

.footerSocial a:hover {
  color: #878787;
  text-decoration: none;
}

.cardsGroup {
  display: flex;
  justify-content: space-between;

  @media all and (max-width: 480px) {
    margin: 0px;
    padding: 0px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  @media all and (min-width: 481px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  @media all and (min-width: 1326px) {
    flex-direction: row;
  }
}

.logo {
  width: 12rem;
  text-align: center;
}
