.button {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    color: inherit;
    display: flex;
    padding: 0.5em;
    height: 3em;
    width: 3em;
    outline: 0 !important;

    &:hover {
        background-color: rgba(black, 0.1);
        color: inherit;
        text-decoration: none;
    }
    
    svg {
        font-size: 1.5rem;
        margin: auto;
    }
}