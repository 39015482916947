tbody.body tr td {
    background-color: #FFFFFF;
    border-top-width: 0;
    vertical-align: middle;
    // white-space: nowrap;

    &:first-child {
        border-top-left-radius: 0.625em;
        border-bottom-left-radius: 0.625em;
    }

    &:last-child {
        border-top-right-radius: 0.625em;
        border-bottom-right-radius: 0.625em;
    }
}