.button {
    display: block;
    border: none;
    background-color: #9966ff;
    color: #FFFFFF;
    padding: 10px 20px; 
    border-radius: 5px;
    text-align: center; 
    cursor: pointer;
    position: relative; 
    box-sizing: border-box; 
}

.button:hover {
    color: #49169c;
}

.loading .loader {
    opacity: 1;
    pointer-events: all;
}

.loader {
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    font-size: 0.75rem;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 200ms ease-in-out;
    z-index: 10;
}

.loader .spinner {
    margin: auto;
    height: 2em;
    width: 2em;
}
