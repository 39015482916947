.errorStyling {
  color: red;
  font-size: 0.88rem;
}

.Button {
  margin-right: 10px;
}

.filtro {
  th {
    position: relative;
  }
}
