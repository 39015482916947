@import './styles/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

#root {
  height: 100%;
}

/** {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Rubik';
}

body {
  background-color: #f8fafb;
  height: 100%;
}

a {
  text-decoration: none;
}



.d-block {
  display: block;
}

.ml-auto {
  margin-left: auto;
}


*/