.inputIcon {
    position: absolute;
    right: 10px;
}

input::-ms-reveal 
{
    display: none;
}

.form-group {
    margin-bottom: 50px;
}

.formGroup {
    margin-top: 10px;
    position: relative;
    display: flex;
    align-items: center;
}