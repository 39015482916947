.root {
  display: flex;
  flex-direction: column;
}

.header {
  position: sticky;
  top: 0;
}

.pageHeader {
  align-items: center;
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  overflow-x: hidden;
  width: 100%;

  .back {
    margin-left: -3rem;
    height: 3rem;
    width: 3rem;
    transition: all 200ms;

    &.visible {
      margin-left: 0;
    }
  }

  h1 {
    font-size: 1.40rem;
    line-height: 2rem;
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.newContainer {
  max-width: 90%;
  margin: 0 auto 0 auto;
}

.content {
  position:relative;
  top: 20%;
}