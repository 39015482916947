.errorStyling {
  color: red;
  font-size: 0.88rem;
}

.Button {
  margin-right: 10px;
}

.addLead {
  background-color: #9966ff !important;
  color: #FFFF !important;
}

.addLead:hover {
  color: #49169c !important;
}

.filtro {
  th {
    position: relative;
  }
}