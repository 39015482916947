nav.nav-header {
  background-color: #DBDBDB !important;
}

.logo {
  max-width: 10rem;
}

@media (min-width: 992px) {
  .container-header {
      max-width: 93% !important;
  }
}
