:root {
  --main-bg-color: brown;
  --light-blue: #1a79c4;
  --dark-blue: #034678;
  --white: #fff;
  --paddingDesktop: 0rem 15rem;
  --paddingLaptop: 0rem 5rem;
  --paddingTablet: 0rem 1rem;
  --paddingPhone: 0px 0px;
  --cellPhones: 320px;
  --tablets: 768px;
  --desktops: 1200px;
  --white-price: #fff;
  --light-blue-price: #ecf5fe;
  --light-gray-price: #f6f6f6;
  --lightmedium-gray-price: #e4e4e4;
  --medium-gray: #a7a7a7;
  --dark-gray-price: #525252;
  --extradark-gray-price: #414141;
  --green-price: #2ecc71;
}

.allContainer {
  @media all and (max-width: 480px) {
    // border: red 50px solid;
  }
  @media all and (min-width: 481px) {
    // border: blue 50px solid;
  }
  @media all and (min-width: 768px) {
    // border: green 50px solid;
    // padding: 0px 50px 0px 50px;
  }
}

.HeaderContainer {
  // background: var(--light-blue);
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg, #262626 8%, #313030 25%, #373636 37%, #392f4b 58%, #49169c 92%, #49169c 100%);
  color: #fff;
  display: flex;
  align-items: center;
  // justify-content: space-around;
  // border: solid 10px green;
  padding: var(--paddingDesktop);
  @media all and (max-width: 480px) {
    padding: var(--paddingPhone);
    padding-bottom: 50px;
    flex-direction: column;
    // border: red 50px solid;
  }
  @media all and (min-width: 481px) {
    flex-direction: column;
    padding: var(--paddingTablet);
    padding-bottom: 50px;
    // border: blue 50px solid;
  }
  @media all and (min-width: 768px) {
    flex-direction: row;
    padding: var(--paddingLaptop);
    padding-top: 4rem;
    padding-bottom: 4rem;
    // border: green 50px solid;
  }
  @media all and (min-width: 1020px) {
    flex-direction: row;
    padding: var(--paddingDesktop);
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
    // border: orange 50px solid;
  }
}

.HeaderContainerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: var(--paddingDesktop);
  padding-bottom: 100px;
  padding-top: 100px;
  // border: solid 5px orange;
  @media all and (max-width: 480px) {
    // border: red 50px solid;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    flex-direction: column;
  }
  @media all and (min-width: 481px) {
    flex-direction: column;
    padding: 25px;
    padding-bottom: 0px;
    // border: blue 10px solid;
    margin-bottom: 0px;
  }
  @media all and (min-width: 768px) {
    flex-direction: row;
    // border: green 10px solid;
  }
}

.HeaderContainerDiv img {
  width: 20rem;
  // border: solid 5px red;
  @media all and (max-width: 480px) {
    display: none;
  }
}

.Header h1 {
  font-weight: 700;
  font-size: 48px;
  padding: 0;
  margin: 0;
  // border: solid 5px green;
}
.Header h3 {
  font-weight: 700;
  font-size: 24px;
  padding: 0;
  margin: 0;
  // border: solid 5px white;
}
.HeaderContainerDiv p {
  // border: solid 5px red;
}

.SpecsContainer {
  background: #DBDBDB;
  color: var(--dark-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--paddingDesktop);

  // border: red 50px solid;
  @media all and (max-width: 480px) {
    padding: var(--paddingPhone);
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    // border: red 50px solid;
  }
  @media all and (min-width: 481px) {
    // flex-direction: column;
    padding: var(--paddingPhone);
    padding-top: 2rem;
    padding-bottom: 2rem;
    // border: blue 50px solid;
  }
  @media all and (min-width: 768px) {
    // flex-direction: row;
    padding: var(--paddingTablet);
    padding-top: 2rem;
    padding-bottom: 2rem;
    // border: green 50px solid;
  }
  @media all and (min-width: 1020px) {
    // flex-direction: row;
    padding: var(--paddingDesktop);
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
    // border: orange 50px solid;
  }
}
.SpecsContainer h1 {
  color: #000000;
  font-weight: 700;
  font-size: 40px;
  padding: 0px 0px 20px 0px;
}

.SpecsItemsArea {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0px;
  // border: solid 2px blue;
  // width: 15rem;
}

// .SpecsItemsArea p {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   padding: 10px;
// }

.SpecsItem {
  display: flex;
  flex-direction: column;
  margin: 10px 10px 15px 10px;
  flex-wrap: wrap;
  flex: 1 0 31%;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 25px 15px 0px 15px;
  border: solid 2px rgba(5, 5, 5, 0.04);
  border-radius: 15px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 8px 9px 13px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 8px 9px 13px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 8px 9px 13px -12px rgba(0, 0, 0, 0.75);
  @media all and (max-width: 480px) {
    // border: green 2px solid;
    height: 30rem;
    width: 100%;
  }
  @media all and (min-width: 481px) {
    height: 25rem;
    width: 8rem;

    // border: red 2px solid;
  }

  @media all and (min-width: 768px) {
    height: 22rem;
    width: 8rem;
    // border: green 2px solid;
  }
  @media all and (min-width: 1020px) {
    height: 22rem;
    width: 8rem;
    // border: orange 2px solid;
  }
}
.SpecsItem h1 {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  // border: solid 2px red;
}
.SpecsItem h4 {
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  // margin-top: 20px;
  padding: 20px 0px 0px 0px;
  /* border: solid 2px green; */
}
.SpecsItem p {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  // border: solid 10px yellow;
}

.SpecsIcon {
  color: #9966ff;
  font-size: 100px;
  background-color: #f1f1f1;
  padding: 25px;
  border-radius: 50%;
  border: solid #00000030;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.QualitySpecsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg, #262626 8%, #313030 25%, #373636 37%, #392f4b 58%, #49169c 92%, #49169c 100%);
  color: var(--white);
  // border: solid 10px yellow;
  width: 100%;
  text-align: center;
  padding: var(--paddingDesktops);
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media all and (max-width: 480px) {
    // border: red 50px solid;
    flex-direction: column;
  }

  @media all and (min-width: 1020px) {

    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
    // border: orange 50px solid;
  }
}
.QualitySpecsContainer img {
  align-items: center;
  justify-content: center;
  width: 50%;
  @media all and (max-width: 480px) {
    // border: red 50px solid;
    padding-top: 25px;
    width: 100%;
  }
}
.QualitySpecsContainer h1 {
  font-size: 48px;
}
.QualitySpecsContainer h1,
p {
  padding: 15px 0px;
}
.QualitySpecsContainer li {
  line-height: 28px;
}

.QualitySpecDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: left;
  // border: solid red 10px;
  // margin-left: 50px;
  // margin-right: 50px;
  @media all and (max-width: 480px) {
    // border: red 50px solid;
    text-align: center;
    margin: 0;
    padding: 0;
  }
}

.QualitySpecDiv img {
  // display: none;
  width: 100%;
}

.QualitySpecDiv ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.QualitySpecItem {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  flex: 1 1 1;
  align-items: center;
  justify-content: center;
  // width: 32%;
  text-align: left;
  // border: solid green 10px;
  // margin-left: 50px;
  // margin-right: 50px;
  @media all and (max-width: 480px) {
    // border: red 50px solid;
    text-align: center;
    margin: 0;
    padding: 0;
  }
}

.QualitySpecItem img {
  // display: none;
  width: 100%;
}

.QualitySpecItem ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.SpecsIconConsult {
  display: flex;
  flex-direction: column;
  margin: 10px 10px 15px 10px;
  flex-wrap: wrap;
  flex: 1 0 30%;
  // align-items: center;
  text-align: center;
  justify-content: center;
  height: 25rem;
  width: 15rem;
  padding-top: 1.2rem;
  border: solid 1px rgba(36, 36, 36, 0.1);
  border-radius: 15px;
  background-color: #57506b;
  -webkit-box-shadow: 8px 9px 13px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 8px 9px 13px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 8px 9px 13px -12px rgba(0, 0, 0, 0.75);
  // border: yellow 2px solid;
  @media all and (max-width: 480px) {
    // border: red 50px solid;
    height: 300px;
  }
}

.SpecsIconConsult h4 {
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  // margin-top: 20px;
  padding: 10px 0px 10px 0px;
  // border: solid 2px green;
}

.SpecsIconConsult ul {
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  text-align: left;
  justify-content: center;
  // border: solid 10px red;
}
.SpecsIconConsult ul li {
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  text-align: left;
  justify-content: center;
  // border: solid 10px yellow;
}
.SpecsIconHead {
  // border: solid 3px red;
  height: 30%;
}
.SpecsIconList {
  height: 70%;
  // display: flex;
  // justify-content: space-evenly;
  // border: solid 3px green;
}
.SpecsIconList ul li {
  line-height: 0.9rem;
  justify-content: space-evenly;
  padding: 5px 10px;
  border-bottom: solid 0.5px gray;
}

.SpecsIconList ul li:first-child {
  border-top: solid 0.5px gray;
}

.pricingContainer {
  border: solid 10px yellow;
}
