@import '../../styles/mixins';

.root {
    display: flex;
    height: 100%;
}

.page {
    flex: 1;
    flex-basis: 55%;

    & > div {
        margin-bottom: 0;
        
        & > div {
            display: flex;
            height: 100%;
        }
    }
}

@keyframes appearFromLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
      }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}


.card {
    margin: auto;
    width: 320px;
    height: 25rem;
    animation: appearFromLeft 1s;    
}


.FormInput{
    margin-top: 10px;
}


.quote {
    flex: 1;
    flex-basis: 45%;
    height: 100%;
    position: relative;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            display: block;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}

.content{
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between; 
}

.links{
  text-decoration: none;
  transition: color 0.2s; 
}


.send{
    font-size: 18px;
    margin: auto;
    display: block;
    width: 80%;
    height: 25rem;
    animation: appearFromLeft 1s;    
    
}

.text{
    margin-left: auto;
    margin-right: auto;
    width: 35em
}

.BiCheckShield{
    margin: 0 auto 20px auto;  
    width: 6em;  
}

.BiArrowToLeft{
    width: 6em;
    margin: 80px auto 0 auto;  
}


