.extra {
    opacity: 0;
    pointer-events: none;
    position: absolute;

    &.show {
        opacity: 1;
        pointer-events: all;
        position: relative;
    }
}