 .titleForm {
  color: #000000;
 }

 .email{
    margin-top: 20px;    
    text-align: center;
  }

  .errorStyling {
    color: red;
    font-size: 0.88rem;
  };

  .partnerStyling {
    display: flex;
    align-items: center;
  };

  .termsStyling {
    font-size: 12px;
    padding: 5px;
  };  

  .ChecktermsStyling   {
    display: flex;
    align-items: center;
  }

  .ChecktermsStyling  label {
    border: 1px solid transparent;
  }

  .requiredFields {
    font-size: 0.85rem;
  }

  .requiredFields span {
    font-size: 0.99rem;
  }



  