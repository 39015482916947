@import "../../styles/mixins";

.root {
  display: flex;
  height: 100%;
  background-color: black;
}

.textLogo {
  text-align: center;
}

.logo {
  max-width: 90%;
  margin-bottom: 35px;
}

.cardLogin {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -10%);
  animation: appearFromLeft 1s forwards;
}

.page {
  flex: 1;
  flex-basis: 55%;

  & > div {
    margin-bottom: 0;

    & > div {
      display: flex;
      height: 100%;
    }
  }
}

.card {
  width: 450px;

  @keyframes appearFromLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.card h2 {
  color: #FFFFFF;
  font-size: 25px;
  font-weight: 400;
}

.title {
  text-align: center;
  margin-bottom: 30px;
}

.formImput {
  width: 100%;
}

// .form {
//   line-height: 10px;
// }

.formSubmit {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.quote {
    flex: 1;
    background: url(/img/background-dnasec-secondary.png) no-repeat;
    background-size: cover; 
}

.content {
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
}

.links {
  text-decoration: none;
  color: #FFFFFF;
  transition: color 0.2s;
}
